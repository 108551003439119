import { render, staticRenderFns } from "./police.vue?vue&type=template&id=d89f2fa6&scoped=true&"
import script from "./police.vue?vue&type=script&lang=js&"
export * from "./police.vue?vue&type=script&lang=js&"
import style0 from "./police.vue?vue&type=style&index=0&id=d89f2fa6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d89f2fa6",
  null
  
)

export default component.exports